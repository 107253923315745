import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import OfficeUserMappingEdit from "@components/office-user-mapping/Edit"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "office-user-mapping.edit"
}

const OfficeUserMappingEditPage = (props) => (
  <>
    <Seo title="Edit Office-User Mapping" />
    <div className="main-wrapper">
      <Sidebar menuActive="settings"
        action="Edit" />
      <OfficeUserMappingEdit
        menu="Edit Office-User Mapping"
        parentPath="office-user-mapping"
        groupMenu="settings"
        pageType="Edit"        
        additionalParams={AdditionalParams} />
    </div>
  </>
)

export default OfficeUserMappingEditPage;
