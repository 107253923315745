import React from "react";
import BrandLogo from "../../../images/logo-small.svg"

const Loading = (props) => {
  return (
    <>
      {
        props.pageType === "List" ? (
          <div className="d-flex align-items-center justify-content-center">
            <img style={{marginTop: '35px', marginBottom: '50px'}}
              src={BrandLogo}
              alt="logo"
            />
          </div>
        ) : (
          <div className="main-content d-flex align-items-center justify-content-center">
            <div className="page-content">
              <img
                src={BrandLogo}
                alt="logo"
              />
            </div>
          </div>
        )
      }
    </>
  )
}

export default Loading;